import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

const DeactivatedBanner = ({ children }) => {
  const { state } = useContext(AuthContext);

  return (
    <>
      {state.user.attributes["custom:isDeactivated"] === "1" && (
        <>
          <div className="deactivated-banner d-flex px-2 px-md-0 justify-content-center my-auto align-items-center">
            <h4 className="deactivated-banner-div px-4 text-center">
              Your account has been deactivated and you will not be able to
              upload new properties. Please contact the administrator for more
              details
            </h4>
          </div>

          <style>{`
                .deactivated-banner {
                    min-height: 25px;
                    background: #FAE1E3;
                }	
                .deactivated-banner-div{
                    font-size: 14px;
                    margin-top: 10px;        
                }
                @media (max-width: 600px) {
                    .deactivated-banner-div{
                        font-size: 12.5px !important;
                    }
                }
			
            `}</style>
        </>
      )}
      {children}
    </>
  );
};

export default DeactivatedBanner;
