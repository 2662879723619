import { websiteUrl } from "./config";

const awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION
    ? process.env.REACT_APP_AWS_PROJECT_REGION
    : "us-east-1",
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION
    ? process.env.REACT_APP_AWS_COGNITO_REGION
    : "us-east-1",
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID
    ? process.env.REACT_APP_AWS_USER_POOLS_ID
    : "us-east-1_HfmqbBOKV",
  aws_user_pools_web_client_id: process.env
    .REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
    ? process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
    : "604deg3s5oqf6692338ovkcai2",
  authenticationFlowType: "code" | "implicit",
  oauth: {
    domain: process.env.REACT_APP_AWS_DOMAIN
      ? process.env.REACT_APP_AWS_DOMAIN
      : "dev-auth.mytripify.com",
    redirectSignIn: websiteUrl,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignOut: websiteUrl,
    responseType: "code",
  },
  cookieStorage: {
    domain: process.env.REACT_APP_HOST_NAME || "localhost",
    secure: true,
    path: "/",
    expires: 365,
  },
};

export default awsconfig;
