import "./App.css";
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import AuthContext from "./contexts/AuthContext";
import { Route, Routes, useLocation } from "react-router-dom";
import UnAthenticatedRoutes from "./routes/UnAuthenticatedRoutes";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "./components/LoadingSpinner";
import { setJwtToken } from "./axios";
import awsconfig from "./config/aws.exports";
import { webCliendId } from "./config/config";
import * as workerTimers from "worker-timers";
import Page404 from "./pages/404Page";

function App() {
  const [state, setState] = useState({
    user: { attributes: {} },
    signedIn: null,
    refreshToken: null,
    domain: null,
  });
  const [eventUpdate, setEventUpdate] = useState(false);

  const location = useLocation();

  const checkSession = async () => {
    let user = { attributes: {} },
      signedIn = false;

    try {
      user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      signedIn = true;
      // await listAllEvents(user, signedIn);
      setJwtToken(user.signInUserSession.idToken.jwtToken);
      refreshToken();
    } catch (err) {
      console.log("cs -- error log -- ", err);
    }
    setState({ ...state, user, signedIn });
  };

  const refreshToken = async () => {
    let refreshToken = workerTimers.setInterval(async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        user.refreshSession(session.refreshToken, (err, session) => {
          const { idToken } = session;
          setJwtToken(idToken.jwtToken);
        });
      } catch (err) {
        clearInterval(refreshToken);
        console.log("cs -- error log -- ", err);
        window.location.href = "/logout";
      }
    }, 60 * 60 * 1000);
    setState({ ...state, refreshToken });
  };

  const setAuth = async (user = {}, signedIn = true) => {
    clearInterval(refreshToken);
    if (signedIn) {
      refreshToken();
      // await listAllEvents();
    }
  };

  useEffect(() => {
    // if (location.pathname === "/RequestAccess") {
    //   Auth.configure({
    //     ...awsconfig,
    //     aws_user_pools_web_client_id: process.env
    //       .REACT_APP_CHECKIN_APP_CLIENT_ID
    //       ? process.env.REACT_APP_CHECKIN_APP_CLIENT_ID
    //       : "4ddim2o2kpq3o350n3o90sbsm6",
    //   });
    // } else {
    Auth.configure({
      ...awsconfig,
      aws_user_pools_web_client_id: webCliendId,
    });
    // }
    checkSession();
  }, [location]);

  return (
    <>
      <AuthContext.Provider
        value={{
          state,
          setValue: setState,
          setAuth: setAuth,
          refreshToken,
          checkSession,
          setEventUpdate,
          eventUpdate,
        }}
      >
        <React.Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            {state.signedIn === null ? (
              <Route path="*" element={<LoadingSpinner />} />
            ) : state.signedIn ? (
              <Route path="*" element={<AuthenticatedRoutes state={state} />} />
            ) : state.signedIn === false ? (
              <Route
                path="*"
                element={
                  <UnAthenticatedRoutes
                    signedIn={state.signedIn}
                    user={state.user}
                  />
                }
              />
            ) : (
              <></>
            )}
          </Routes>
        </React.Suspense>
        <ToastContainer />
      </AuthContext.Provider>
    </>
  );
}

export default App;
