import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DeactivatedBanner from "../components/Header/DeactivatedBanner";

const PropertiesUpload = React.lazy(() =>
  import("../pages/Users/PropertiesUpload")
);

const ListPropertiesIndividualUpload = React.lazy(() =>
  import("../pages/Users/ListPropertiesOfUpload")
);

const ListAllProperties = React.lazy(() =>
  import("../pages/Users/ListAllProperties")
);

const InvestorSettings = React.lazy(() =>
  import("../pages/Users/InvestorSettings")
);
const IndividualInvestorSettings = React.lazy(() =>
  import("../pages/Users/IndividualInvestorSettings")
);

const ListAllFormulas = React.lazy(() =>
  import("../pages/Users/ListAllFormulas")
);

const ListIndividualFormulas = React.lazy(() =>
  import("../pages/Users/ListIndividualFormulas")
);

const IndividualConfigurationSettings = React.lazy(() =>
  import("../pages/Users/IndividualConfigurationSettings")
);

const ListAdminFormulas = React.lazy(() =>
  import("../pages/UserManagement/ListAdminFormulas")
);

const UserManagementPage = React.lazy(() =>
  import("../pages/UserManagement/UserManagementPage")
);

const FirmsPage = React.lazy(() => import("../pages/UserManagement/ViewFirms"));

const UsersOfIndividualFirm = React.lazy(() =>
  import("../pages/UserManagement/UsersOfIndividualFirm")
);

const ProfilePage = React.lazy(() => import("../pages/Users/ProfilePage"));

export default function AuthenticatedRoutes({ state }) {
  const getNavigateUrl = () => {
    // if (state.user.signInUserSession.accessToken.payload["cognito:groups"]) {
    //   return "/user-management/all-users";
    // } else {
    return "/all-uploads";
    // }
  };

  return (
    <DeactivatedBanner>
      <Routes>
        <Route path="/all-uploads" element={<PropertiesUpload />} />

        <Route
          path="/all-uploads/:uploadId/uploads"
          element={<ListPropertiesIndividualUpload />}
        />
        <Route
          path="/all-uploads/:uploadId/uploads/formulas"
          element={<ListIndividualFormulas />}
        />
        <Route
          path="/all-uploads/:uploadId/uploads/configuration-settings"
          element={<IndividualConfigurationSettings />}
        />
        <Route
          path="/all-uploads/:uploadId/uploads/investor-settings"
          element={<IndividualInvestorSettings />}
        />

        <Route path="/all-properties" element={<ListAllProperties />} />
        <Route
          path="/all-properties/configuration-settings"
          element={<IndividualConfigurationSettings />}
        />
        <Route path="/investor-settings" element={<InvestorSettings />} />
        <Route path="/formulas" element={<ListAllFormulas />} />
        <Route path="/admin-formulas" element={<ListAdminFormulas />} />
        {/* <Route
        path="/all-uploads/:uploadId/uploads/formulas"
        element={<ListIndividualFormulas />}
      /> */}

        <Route
          exact={false}
          path="/user-management/*"
          element={<UserManagementPage />}
        />

        <Route path="/firms" element={<FirmsPage />} />

        <Route
          path="/firms/:firmId/firm-users"
          element={<UsersOfIndividualFirm />}
        />

        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/*" element={<Navigate to={getNavigateUrl()} replace />} />
      </Routes>
    </DeactivatedBanner>
  );
}
