import { Spinner, RainbowThemeContainer } from "react-rainbow-components";
const theme = {
  rainbow: {
    palette: {
      brand: "#F25F5C",
    },
  },
};
const LoadingSpinner = ({ className, style }) => {
  return (
    <>
      <div
        className={`spinner d-flex justify-content-center align-items-center position-relative ${
          className ? className : ""
        }`}
        style={style}
      >
        <RainbowThemeContainer theme={theme}>
          <Spinner size="large" type="arc" variant="brand" />
        </RainbowThemeContainer>
      </div>
      <style jsx>{`
        .spinner {
          height: 400px;
        }
        .spinner:before {
          border-top-color: transparent;
        }
      `}</style>
    </>
  );
};

export default LoadingSpinner;
