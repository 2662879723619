import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";

const Login = React.lazy(() => import("../pages/UnAuth/Login"));
const RequestAccess = React.lazy(() => import("../pages/UnAuth/RequestAccess"));
const ResetPassword = React.lazy(() => import("../pages/UnAuth/ResetPassword"));

export default function UnAthenticatedRoutes({ signedIn, user }) {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/login"
          element={<Login signedIn={signedIn} user={user} />}
        />
        <Route
          path="/request-access"
          element={<RequestAccess signedIn={signedIn} user={user} />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path={"/*"} element={<Navigate replace to="/login" />} />
      </Routes>
    </Suspense>
  );
}
